<template>
  <v-row>
    <v-col cols="4" class="text-right">
      <div style="height: 50px"></div>
      <div class="text-caption">Front Left</div>
      <div class="text-subtitle-1">
        {{ config.frontLeft.toLocaleString('en-GB', { maximumFractionDigits: 2 }) }} {{ unit }}
      </div>
      <div style="height: 130px"></div>
      <div class="text-caption">Rear Left</div>
      <div class="text-subtitle-1">
        {{ config.rearLeft.toLocaleString('en-GB', { maximumFractionDigits: 2 }) }} {{ unit }}
      </div>
    </v-col>
    <v-col cols="4">
      <v-row justify="center"
        ><v-col cols="auto"> <v-img src="@assets/car-top-alpha.png" width="150" height="300"></v-img> </v-col
      ></v-row>
    </v-col>
    <v-col cols="4">
      <div style="height: 50px"></div>
      <div class="text-caption">Front Right</div>
      <div class="text-subtitle-1">
        {{ config.frontRight.toLocaleString('en-GB', { maximumFractionDigits: 2 }) }} {{ unit }}
      </div>
      <div style="height: 130px"></div>
      <div class="text-caption">Rear Right</div>
      <div class="text-subtitle-1">
        {{ config.rearRight.toLocaleString('en-GB', { maximumFractionDigits: 2 }) }} {{ unit }}
      </div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'SetupWheelInfo',
  props: { unit: String, config: Object }
}
</script>
