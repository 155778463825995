<template>
  <div>
    <v-row justify="center">
      <v-col cols="12" sm="4" class="text-right">
        <div class="text-caption">Rear</div>
        <div class="text-subtitle-1">
          {{ config.rear.toLocaleString('en-GB', { maximumFractionDigits: 2 }) }} {{ unit }}
        </div>
      </v-col>
      <v-col cols="12" sm="4" class="text-left">
        <div class="text-caption">Front</div>
        <div class="text-subtitle-1">
          {{ config.front.toLocaleString('en-GB', { maximumFractionDigits: 2 }) }} {{ unit }}
        </div>
      </v-col>
    </v-row>
    <v-row justify="center"
      ><v-col cols="auto"> <v-img src="@assets/car-top-alpha-90.png" width="300" height="150"></v-img> </v-col
    ></v-row>
  </div>
</template>

<script>
export default {
  name: 'SetupAxisInfo',
  props: { unit: String, config: Object }
}
</script>
